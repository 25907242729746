<template>
  <div class="moderation-section">
    <!-- Moderation Status Display -->
    <div v-if="content.isDraft && content.moderationStatus === 0" class="justify-content-between mt-2">
      <b-card class="border bg-light">
        <h5 class="mb-2">
          {{ $t('forums.moderation.title') }}
        </h5>
        
        <!-- Moderation Feedback -->
        <div v-if="content.moderationFeedback" class="mb-2">
          <div class="d-flex align-items-center mb-1">
            <feather-icon
              :icon="content.moderationFeedback.isAcceptable ? 'CheckCircleIcon' : 'AlertCircleIcon'"
              :class="content.moderationFeedback.isAcceptable ? 'text-success' : 'text-danger'"
              size="18"
              class="mr-1"
            />
            <span :class="content.moderationFeedback.isAcceptable ? 'text-success' : 'text-danger'">
              {{ content.moderationFeedback.isAcceptable ? $t('forums.moderation.acceptable') : $t('forums.moderation.not-acceptable') }}
            </span>
          </div>

          <!-- Reasons -->
          <div v-if="content.moderationFeedback.reasons && content.moderationFeedback.reasons.length > 0">
            <strong class="d-block mb-1">{{ $t('forums.moderation.reasons') }}:</strong>
            <ul class="pl-3 mb-0">
              <li v-for="(reason, index) in content.moderationFeedback.reasons" :key="index">
                {{ reason }}
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p>
            {{ $t('forums.moderation.no-feedback') }}
          </p>
        </div>

        <!-- Moderation Actions -->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            variant="outline-danger"
            class="mr-1"
            @click="startModeration('reject')"
          >
            {{ $t('forums.moderation.reject') }}
          </b-button>
          <b-button
            variant="success"
            @click="startModeration('accept')"
          >
            {{ $t('forums.moderation.accept') }}
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Verified content -->
    <div v-if="content.moderationStatus === 1">
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CheckCircleIcon"
          size="16"
          class="text-success mr-1"
        />
        <small class="text-muted">
          {{ $t('forums.moderation.verified-contribution') }}
        </small>
      </div>
    </div>
    <div v-if="content.moderationStatus === 2">
      <div class="d-flex align-items-center">
        <feather-icon
          icon="AlertCircleIcon"
          size="16"
          class="text-danger mr-1"
        />
        <small class="text-muted">
          {{ $t('forums.moderation.not-verified-contribution') }}
        </small>
      </div>
    </div>

    <!-- Moderation Feedback Modal -->
    <b-modal
      v-model="isModerationFeedbackModalVisible"
      :title="$t('forums.moderation.feedback-modal.title')"
      centered
    >
      <b-form>
        <!-- AI Recommendation -->
        <div class="mb-2">
          <h6>{{ $t('forums.moderation.feedback-modal.ai-recommendation') }}</h6>
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="moderationAction === 'accept' ? 'CheckCircleIcon' : 'AlertCircleIcon'"
              :class="moderationAction === 'accept' ? 'text-success' : 'text-danger'"
              size="18"
              class="mr-1"
            />
            <span :class="moderationAction === 'accept' ? 'text-success' : 'text-danger'">
              {{ $t(`forums.moderation.feedback-modal.${moderationAction}-recommendation`) }}
            </span>
          </div>
        </div>

        <!-- Feedback for the User -->
        <b-form-group
          :label="$t('forums.moderation.feedback-modal.user-feedback-label')"
        >
          <b-form-textarea
            v-model="moderationFeedback.feedbackForUser"
            rows="3"
            :placeholder="$t('forums.moderation.feedback-modal.user-feedback-placeholder')"
          />
        </b-form-group>

        <!-- Agreement with AI Recommendation -->
        <b-form-group class="mb-2">
          <b-form-radio-group
            v-model="moderationFeedback.agreesWithAI"
            :options="[
              { text: $t('forums.moderation.feedback-modal.agree-with-ai'), value: true },
              { text: $t('forums.moderation.feedback-modal.disagree-with-ai'), value: false }
            ]"
          />
        </b-form-group>

        <!-- Feedback for the AI agent -->
        <b-form-group
          v-if="moderationFeedback.agreesWithAI === false"
          :label="$t('forums.moderation.feedback-modal.ai-feedback-label')"
        >
          <b-form-textarea
            v-model="moderationFeedback.aiFeedback"
            rows="3"
            :placeholder="$t('forums.moderation.feedback-modal.ai-feedback-placeholder')"
          />
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="isModerationFeedbackModalVisible = false"
        >
          {{ $t('forums.moderation.feedback-modal.cancel') }}
        </b-button>
        <b-button
          :variant="moderationAction === 'accept' ? 'success' : 'danger'"
          @click="submitModeration"
        >
          {{ $t(`forums.moderation.feedback-modal.${moderationAction}`) }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'ModerationSection',
  
  mixins: [ToastNotificationsMixin],

  props: {
    content: {
      type: Object,
      required: true,
    },
    morphType: {
      type: String,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    itemTypeName: {
      type: String,
      required: true
    },
    parentKey: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isModerationFeedbackModalVisible: false,
      moderationAction: null,
      moderationFeedback: {
        feedbackForUser: '',
        aiFeedback: '',
        agreesWithAI: null
      }
    };
  },

  methods: {
    startModeration(action) {
      this.moderationAction = action;
      
      // Reset feedback object
      this.moderationFeedback = {
        feedbackForUser: '',
        aiFeedback: '',
        agreesWithAI: null
      };
      
      // Set default feedback messages
      if (action === 'accept') {
        this.moderationFeedback.feedbackForUser = this.$t('forums.moderation.feedback-modal.default-accept-user');
      } else {
        this.moderationFeedback.feedbackForUser = this.$t('forums.moderation.feedback-modal.default-reject-user');
      }
      
      this.isModerationFeedbackModalVisible = true;
    },

    async submitModeration() {
      try {
        // Create moderation check object
        const moderationCheck = {
          isAccepted: this.moderationFeedback.agreesWithAI,
          improveComment: this.moderationFeedback.aiFeedback
        };

        // const itemTypePlural = `${this.itemType}s`;

        // Update the content with moderation data
        const response = await this.$store.dispatch('editItem', {
          item: {
            itemType: this.itemType,
            morphType: this.morphType,
            requestConfig: {
              [`${this.itemTypeName}Key`]: this.content.key,
              parentKey: this.parentKey,
              isDraft: false,
              moderationStatus: this.moderationAction === 'accept' ? 1 : 2,
              feedbackForUser: this.moderationFeedback.feedbackForUser,
              moderationCheck: moderationCheck
            },
          },
        });
        console.log('response', response);

        // Emit event to parent for handling success
        this.$emit('moderation-complete', {
          action: this.moderationAction,
          contentKey: this.content.key,
          response: response
        });

        // Show success notification
        this.notifySuccess(
          this.$t(`forums.moderation.success-${this.moderationAction}`)
        );

        // Close modal and reset state
        this.isModerationFeedbackModalVisible = false;
        this.moderationAction = null;
        this.moderationFeedback = {
          feedbackForUser: '',
          aiFeedback: '',
          agreesWithAI: null
        };
      } catch (error) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    }
  }
};
</script> 