<template>
  <widget class="post mx-auto post-card" :enable-card-size="enableCardSize">
    <div>
      <!-- ** Header ** -->
      <div class="pt-1 w-100 d-flex align-items-center">
        <!-- Author avatar -->
        <safe-img
          class="logo"
          :src="createdBy.logo"
          :placeholder="createdBy.placeholder"
        />

        <!-- Author and date -->
        <div class="ml-1">
          <div class="d-flex">
            <p v-if="createdBy.name" class="font-weight-extrabold mb-0">
              {{ createdBy.name }}
            </p>
          </div>
          <small
            class="text-muted mb-0"
            :title="exactDate"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            {{ date }}
          </small>
        </div>

        <!-- Edit post button -->
        <b-button
          v-if="canEditPost"
          variant="link"
          class="ml-auto p-50"
          @click="$emit('edit-post', post)"
        >
          <b-icon-pencil />
        </b-button>
      </div>
    </div>

    <!-- ** Text ** -->
    <div class="my-1 border-bottom">
      <append-preview :enabled="!!hasPreview && isContentReady">
        <div
          ref="content"
          class="text-break"
          :class="{ 'clip-content': isClippingContent }"
        >
          <content-with-anchors
            :content="post.content"
            @content-ready="isContentReady = true"
          />
        </div>
        <template #footer>
          <a
            v-if="isContentLong"
            class="d-block text-center text-muted font-weight-bolder mt-1"
            @click="toggleShowMoreContent"
          >
            {{
              isClippingContent
                ? $t("action.show-more")
                : $t("action.show-less")
            }}
          </a>
        </template>
      </append-preview>

      <!-- ** Attachments ** -->
      <div v-if="hasAttachment" class="d-flex justify-content-center">
        <file-preview
          v-for="(file, index) in post.attachments"
          :key="index"
          :has-max-height="true"
          :src="attachmentURL[index]"
          :mime-type="post.attachments[index].mimeType"
        />
      </div>
    </div>

    <!-- ** Aditional content ** -->
    <div v-if="post.thumbnailURL" class="pb-2">
      <img class="w-50" :src="post.thumbnailURL">
    </div>

    <!-- ** Moderation ** -->
    <moderation-section
      v-if="canModeratePost"
      :content="post"
      morph-type="posts"
      item-type="posts"
      item-type-name="post"
      :parent-key="post.key"
      @moderation-complete="handleModerationComplete"
    />
    
    <!-- Moderation Feedback Modal -->
    <b-modal
      v-if="canModeratePost"
      v-model="isModerationFeedbackModalVisible"
      :title="$t('forums.moderation.feedback-modal.title')"
      centered
    >
      <b-form>
        <!-- AI Recommendation -->
        <div class="mb-2">
          <h6>{{ $t('forums.moderation.feedback-modal.ai-recommendation') }}</h6>
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="moderationAction === 'accept' ? 'CheckCircleIcon' : 'AlertCircleIcon'"
              :class="moderationAction === 'accept' ? 'text-success' : 'text-danger'"
              size="18"
              class="mr-1"
            />
            <span :class="moderationAction === 'accept' ? 'text-success' : 'text-danger'">
              {{ $t(`forums.moderation.feedback-modal.${moderationAction}-recommendation`) }}
            </span>
          </div>
        </div>

        <!-- Feedback for the User -->
        <b-form-group
          :label="$t('forums.moderation.feedback-modal.user-feedback-label')"
        >
          <b-form-textarea
            v-model="moderationFeedback.feedbackForUser"
            rows="3"
            :placeholder="$t('forums.moderation.feedback-modal.user-feedback-placeholder')"
          />
        </b-form-group>

        <!-- Agreement with AI Recommendation -->
        <b-form-group class="mb-2">
          <b-form-radio-group
            v-model="moderationFeedback.agreesWithAI"
            :options="[
              { text: $t('forums.moderation.feedback-modal.agree-with-ai'), value: true },
              { text: $t('forums.moderation.feedback-modal.disagree-with-ai'), value: false }
            ]"
          />
        </b-form-group>

        <!-- Feedback for the AI agent (only shown when disagreeing) -->
        <b-form-group
          v-if="moderationFeedback.agreesWithAI === false"
          :label="$t('forums.moderation.feedback-modal.ai-feedback-label')"
        >
          <b-form-textarea
            v-model="moderationFeedback.aiFeedback"
            rows="3"
            :placeholder="$t('forums.moderation.feedback-modal.ai-feedback-placeholder')"
          />
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="isModerationFeedbackModalVisible = false"
        >
          {{ $t('forums.moderation.feedback-modal.cancel') }}
        </b-button>
        <b-button
          :variant="moderationAction === 'accept' ? 'success' : 'danger'"
          @click="submitModeration"
        >
          {{ $t(`forums.moderation.feedback-modal.${moderationAction}`) }}
        </b-button>
      </template>
    </b-modal>

    <!-- ** Social buttons ** -->
    <social-buttons
      :comments-count="post.commentCount"
      :liked-by-member="post.likedByMember"
      :total-likes="post.totalLikes"
      :is-sending="isSending"
      :is-widget="isWidget"
      @change-like="toggleLike"
      @comment="toggleComment"
    />

    <!-- ** Comment form ** -->
    <comment-form
      v-if="isCommenting"
      ref="commentForm"
      :source-key="post.key"
      morph-type="post"
      item-type="posts"
      class="mt-1"
      @created="handleCommentCreation"
    />

    <!-- ** Comments list ** -->
    <comments-list
      v-if="isCommenting"
      class="mt-2"
      :source-key="post.key"
      :can-moderate="canModeratePost"
      morph-type="post"
      @delete-comment="deleteComment"
    />
  </widget>
</template>

<script>
import { BButton, BIconPencil } from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
import Widget from '@core/widgets/Widget.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import AvatarPlaceholder from '@/assets/images/placeholders/light/community-avatar.jpeg';
import SocialButtons from '@core/components/social/SocialButtons.vue';
import CommentForm from '@/views/apps/comments/components/CommentForm.vue';
import CommentsList from '@/views/apps/comments/components/CommentsList.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import AppendPreview from './AppendPreview.vue';
import ModerationSection from '@/@core/components/moderation/ModerationSection.vue';

export default {
  components: {
    BButton,
    Widget,
    SafeImg,
    FilePreview,
    AppendPreview,
    ContentWithAnchors,
    SocialButtons,
    CommentForm,
    CommentsList,
    BIconPencil,
    ModerationSection,
  },
  mixins: [CardWidgetMixin],
  props: {
    post: {
      type: Object,
      required: true,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
    // FIXME: This prop should be removed when post is removed from copernicData,
    // so that all lists of posts use the same logic
    disableStore: Boolean,
  },
  data() {
    return {
      loading: false,
      isClippingContent: true,
      isContentLong: false,
      isCommenting: false,
      isContentReady: false,
      hover: false,
      isSending: false,
      // Moderation
      isModerationFeedbackModalVisible: false,
      moderationAction: null,
      selectedPostForModeration: null,
      moderationFeedback: {
        feedbackForUser: '',
        aiFeedback: '',
        agreesWithAI: null
      },
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    exactDate() {
      const date = new Date(this.post.createdAt);
      return date.toLocaleString();
    },
    date() {
      return this.$moment(this.post.createdAt).locale(this.locale).fromNow();
    },
    hasPreview() {
      return (
        this.post.showPreview && (this.post.attachments || []).length === 0
      );
    },
    hasAttachment() {
      return !this.post.showPreview && this.post.attachments?.length > 0;
    },
    attachmentURL() {
      if (!this.post.attachments?.length) {
        return '';
      }

      const url = [];

      for (const row of this.post.attachments) {
        url.push(row.filePath);
      }

      return url;
    },
    canEditPost() {
      return (
        this.post.createdByMember?.key === this.loggedMember?.key
        || this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'update',
          'post',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    canManagePosts() {
      return checkPermissions(
          'manage',   
          'post',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
    },
    canModeratePost() {
      return this.isStaff || this.canManagePosts;
    },
    createdBy() {
      if (this.post.createdByMember) {
        return {
          name: `${this.post.createdByMember.name} ${this.post.createdByMember.surname}`,
          logo: getImageResource(this.post.createdByMember.avatar_url),
          placeholder: MembersPlaceholder,
        };
      }

      if (this.post.createdByCollective) {
        return {
          name: this.post.createdByCollective.name,
          logo: getImageResource(this.post.createdByCollective.logoURL),
          placeholder: AvatarPlaceholder,
        };
      }

      return { placeholder: MembersPlaceholder };
    },
  },
  async mounted() {
    const { scrollHeight, offsetHeight } = this.$refs.content;
    this.isContentLong = this.$route.name === 'posts-details' ? false : scrollHeight > offsetHeight;
    this.isClippingContent = this.isContentLong;
  },
  methods: {
    deleteComment() {
      --this.post.commentCount;
    },
    toggleShowMoreContent() {
      this.isClippingContent = !this.isClippingContent;
    },
    async toggleComment() {
      this.isCommenting = !this.isCommenting;
      if (this.isCommenting) {
        await this.$nextTick();
        this.$refs.commentForm.focus();
      }
    },
    async toggleLike() {
      if (this.disableStore) {
        // TODO: This should be replaced when implemented the proper post store.
        this.$emit('toggle-like', this.post);
      } else {
        this.isSending = true;
        this.$socket.emit('updateData', {
          communitySlug: this.$store.getters.currentCollective.slug,
          itemType: 'posts',
          key: this.post.key,
          data: { totalLikes: this.post.likedByMember === true ? '--' : '++' },
        });

        await this.$store.dispatch('toggleLike', {
          itemType: 'posts',
          morphType: 'post',
          key: this.post.key,
          item: this.post,
        });
        this.isSending = false;
      }
    },
    handleCommentCreation() {
      this.$socket.emit('updateData', {
        communitySlug: this.$store.getters.currentCollective.slug,
        itemType: 'posts',
        key: this.post.key,
        data: { commentCount: '++' },
      });
    },
    async handleModerationComplete({ action, contentKey, response }) {
      if (response) {
        console.log('handleModerationComplete response', response);
        Object.assign(this.post, response);
      }
      
      this.$emit('moderation-complete', {
        action: action,
        contentKey: contentKey
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.post {
  // this position fixes a bug with the card and the scroll library.
  // if not present the post disapears as we scroll down.
  position: inherit;
}
.clip-content {
  position: relative;
  max-height: 200px;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 3em;
  }
}
</style>
